* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans JP", sans-serif, "Arial", "Helvetica Neue";
}

body {

  background-color: #F1f1f1;
}


.jumbotron {
    padding-top: 290px;
  }
  @media (max-width: 979px) {
    .jumbotron {
      padding-top: 190px;
    }

    .container-fluid {
      padding-top: 90px;
    }
  }

  @media (min-width: 800px) {
    .jumbotron {
        background: linear-gradient(147deg, rgba(233,236,239,1), rgba(1,6,12,0.52)), url('KTN.jpg');
        background-position: center;
        border-radius: 0rem;
    
      }

      .custom-container {
        background-color: #ffffff;
        border-radius: 8px;
        border: #acacac 1px solid;
        -moz-box-shadow: 0 0 5px #ccc;
        -webkit-box-shadow: 0 0 5px #ccc;
        box-shadow: 0 0 3px #ccc;
        padding: 10px;
        
      }

      .container-fluid {
        padding-top: 90px;
        padding-left: 10%;
        padding-right: 10%;
      }
  }

  @media (max-width: 800px) {
    .jumbotron {
        background: linear-gradient(147deg, rgba(233,236,239,1), rgba(1,6,12,0.52)), url('KTN.jpg');
        background-position: center;
        background-size: cover;
        border-radius: 0rem;
    
      }
  }

  .validation-error {
    color: red
  }
  
  .footer-text {
    text-align: left;
  }

  .table {
    max-width: none;
}

.custom-control-label::before, 
.custom-control-label::after {
top: .8rem;
width: 1.25rem;
height: 1.25rem;
}

.hidden {
  opacity: 0; 
  pointer-events: none;
}


.div-animation {
  opacity: 1;
  transition: all 250ms;
  transition-timing-function: cubic-bezeir(0.55, 0, 0.1, 1);
}

.div-onload-animation {
  opacity: 1;
  animation: 250ms  cubic-bezeir(0.55, 0, 0.1, 1) div-onload-animation;
}

@keyframes div-onload-animation {
  0% { opacity : 0 }
  100% { opacity: 1 }
}

/* opacity: 0;
   transition all 500ms ease-in; cubic-bezeir(0.55, 0, 0.1, 1);
   &.show { 
     opacity: 1;
   }
  */

.cleaning-row {
  background-color: #fff;
  border: none;
  color: black;
  padding: 16px 32px;
  
  transition: 0.3s;
}

.cleaning-row:hover {
  background-color: rgb(245, 245, 245);
  color: #fff;
}

.log-row {
  transition: 0.3s;
}

.log-row:hover {
  background-color: rgb(245, 245, 245);
  color: #fff;
}

.log-cell {
  font-size: 24px;
}

.p-box {
  background-color: #fff;
  border: rgb(160, 160, 160) 1px solid;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 4px;
  word-wrap: break-word
}

.btn-margin {
  margin-right: 3px;
  margin-top: 10px;
}

.list-color {
  background: #007bff;
  color: #fff;
  transition: 0.25s ease-in;
}

.list-color:hover {
  background: #003e81;
  color: #fff;
}